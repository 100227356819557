






import { Component, Vue, Prop } from "vue-property-decorator";
import { EventBus } from "@/events/index";

@Component
export default class Delete extends Vue {
  @Prop({ default: false }) protected isCategory!: boolean;
  @Prop() protected id!: number;
  @Prop() protected display_name!: string;
  @Prop() protected is_leaf!: boolean;
  @Prop() protected product_count!: number;
  /**
   * Modal lives in top level parent element under the root (i.e. Category.vue or Mfr.vue etc...)
   * and the editor components (CategoryEditor.vue, AttributeEditor.vue ...)
   * Trigger delete confirmation modal
   * @returns void
   */

  protected toggleDelete() {
    if (this.isCategory) {
      EventBus.$emit("deleteRow", [
        {
          id: this.id,
          display_name: this.display_name,
          is_leaf: this.is_leaf,
          product_count: this.product_count
        }
      ]);
    } else {
      EventBus.$emit("deleteRow", [
        {
          id: this.id,
          display_name: this.display_name,
          product_count: this.product_count
        }
      ]);
    }
  }
}
