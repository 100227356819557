






export default {
  props: {
    id: Number,
    category: { type: Boolean, default: false },
    asset: { type: Boolean, default: false }
  },
  computed: {
    url(): string {
      //@ts-ignore
      if (this.category) {
        return "/category";
        //@ts-ignore
      } else if (this.asset) {
        return "/asset";
      } else {
        //@ts-ignore
        return this.$route.path;
      }
    }
  }
};
