






export default {
  props: {
    mfr: Number
  }
};
