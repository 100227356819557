import { Component, Vue } from "vue-property-decorator";

import AddByCat from "@/components/reusable/menu/menu_options/AddByCat.vue";
import AddProductByMfr from "@/components/reusable/menu/menu_options/AddProductByMfr.vue";
import Delete from "@/components/reusable/menu/menu_options/Delete.vue";
import Duplicate from "@/components/reusable/menu/menu_options/Duplicate.vue";
import Edit from "@/components/reusable/menu/menu_options/Edit.vue";
import Hide from "@/components/reusable/menu/menu_options/Hide.vue";
import ViewLive from "@/components/reusable/menu/menu_options/ViewLive.vue";
import ViewProducts from "@/components/reusable/menu/menu_options/ViewProducts.vue";
import AddProductByCat from "@/components/reusable/menu/menu_options/AddProductByCat.vue";
import ExportProducts from "@/components/reusable/menu/menu_options/ExportProducts.vue";

@Component({
  components: {
    AddProductByCat,
    AddProductByMfr,
    AddByCat,
    Delete,
    Duplicate,
    Edit,
    Hide,
    ViewLive,
    ViewProducts,
    ExportProducts
  }
})
export default class RowMenu extends Vue {}
