






export default {
  props: {
    url: String
  },
  computed: {
    website(): string {
      return process.env.VUE_APP_C5_URL;
    }
  }
};
