








/** This does not link outside app - links to filtered product table. */
export default {
  props: {
    query: String,
    is_leaf: { type: Boolean, default: true }
  },
  computed: {
    website(): string {
      return process.env.VUE_APP_C5_URL;
    }
  }
};
