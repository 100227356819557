






export default {
  props: {
    id: Number
  }
};
