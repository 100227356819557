import { render, staticRenderFns } from "./AddProductByMfr.vue?vue&type=template&id=9a9c9fd6&scoped=true&"
import script from "./AddProductByMfr.vue?vue&type=script&lang=ts&"
export * from "./AddProductByMfr.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a9c9fd6",
  null
  
)

export default component.exports